import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JoinBG from '../../icons/joinBG.jpg';
import project from '../../icons/project.png';
import service from '../../icons/service.png';
import frontEnd from '../../icons/frontEnd.png';
import backEnd from '../../icons/backEnd.png';
import NavMobile from '../component/navMobile'
import './index.scss';
import Customized from '../component/customized'
class Join extends Component {
    state={
         postList:[
            {
                id: 1,
                order: 'NO.1', 
                post: '项目经理',
                img: project,
                postDescription: '1、 担任项目经理，主导大项目的实施，达成项目预定目标；2、主导或协调资源，进行ERP项目的业务调研、诊断、分析，形成满足客户需求的业务解决方案；3、分配团队成员工作任务，辅导团队成员完成项目工作任务，把控任务质量4、知识沉淀与分享，及时沉淀业务中发现的价值要点；5、针对软件产品的销售，提供售前支持。',
                postConditions: '1、大专以上学历，计算机或相关专业毕业； 2、五年以上管理软件项目实施经验，深刻理解软件产品实施；3、具备丰富的项目管理知识和经验，对企业经营管理有一定的认识。4、清晰的表达能力和流畅的沟通能力，有良好的客户服务精神及团队合作能力。'
            },
            {
                id: 2,
                order: 'NO.2',
                post: '前端工程师',
                img: service,
                postConditions: '1.精通HTML、JavaScript、Ajax、CSS等Web开发技术; 2.具有审美和设计能力，注重细节，追求完美, 会简单使用photoshop等工具3.能熟练使用主流的JavaScript框架或JavaScript库, 熟练运用react/ angular / vue中的一种 4.熟悉echarts优先考虑5.对Web标准和兼容性有良好认识，能够高保真还原设计稿，具备良好的代码风格以及接口、架构设计能力。',
                postDescription: '1. 负责整体运营管理系统的前端开发，全系统际化支持；2. 与需求设计人员配合完成代码编写、调试、单元测试；3. 保质保量按时完成自己所担负的开发任务。'
            },
            {
                id: 3,
                order: 'NO.3',
                post: '后端工程师',
                img: frontEnd,
                postConditions: '1.JAVA或C++编程基本功扎实，熟悉常用数据结构和算法;2.熟悉TCP/ IP、HTTP协议相关知识，熟悉Unix / Linux环境和系统编程，深入掌握服务器编程模型；3、熟悉MySQL数据库及主流NoSQL数据库；4、有较好的逻辑思维能力，较强的抽象、概括、总结能力；5、有较好的沟通交流能力，善于主动思考和行动，乐于解决具有挑战性的问题，对待技术有强烈兴趣。',
                postDescription: '1、负责信息流的架构设计及需求开发工作；2、负责后端技术的基础架构改造、服务化、沉淀工具、效能提升等工作。3、负责后端的业务需求分析、架构设计，并承担主力核心设计、研发工作，并确保高质量。4、负责后端技术的基础架构改造、服务化、沉淀工具、效能提升等工作。'
            },
            {
                id: 4,
                order: 'NO.4',
                post: 'UI设计师',
                img: backEnd,
                postDescription: '1.负责整体把控产品UI设计风格；2.根据产品定位、需求及功能，负责产品整体UI（用户界面及交互）设计，为产品的下一步开发提供效果图；3.与开发工程师配合，完成产品界面的设计及开发，以及产品图标、界面美化等工作；4、有丰富的网站和APP用户体验经验。',
                postConditions: '1.精通使用Photoshop、Illustrator等设计软件，熟悉iOS和Android平台开发规范设计，3年-5年工作经验； 2.创新能力强，对设计流程及用户需求有较好理解，并具有很好的沟通能力；3.分析和研究用户的行为，提供相关领域的专业性意见或建议；4.有较强的美术功底且手绘能力强，具有出色的艺术设计能力，能够灵活控制设计风格，把握时代前沿，富有创造力和激情。'
            }
         ],
         title: '欢迎加入',
        content: '世界那么大 来这里就对了'
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { postList, title, content} =this.state;
        return (
            <div className='join'>
                {/* 移动 */}
                <NavMobile current="join"></NavMobile>
                {/* 轮播图 */}
                <div className='join-banner'>
                    <div className='contact-banner-box'>
                        <img src={JoinBG} alt="" />
                    </div>
                   
                </div>
                
                {/* 标题 */}
                <div className='join-title'>
                    <div className='join-title-china'>加入我们</div>
                    <div className='join-title-english'>Join us</div>
                </div>
                 
                 {/* 标语 */}
                <div className='join-slogan'>
                    <div className='join-slogan-wrapper'>
                        <div className='join-slogan-wrapper-item1'>如果你满腹经纶却怀才不遇；如果你有抱负却无施展的舞台；如果你有创意却不被认可；如果你攥着杀牛的本事却天天宰鹅；</div>
                        <div className='join-slogan-wrapper-item2'>那么请停止这一切如果</div>
                        <div className='join-slogan-wrapper-item3'>并不是因为我们有各种不平庸的怪才，傲娇中的淫才，崛起中的骚年，还有觉醒中的贱咖，</div>
                        <div className='join-slogan-wrapper-item4'>而是因为我们懂各种“怀才不遇”</div>
                    </div>
                 </div>

                <div className='join-posts'>
                    <div className='join-posts-wrapper'>
                        <ul>
                            {postList.map((item, index) => <li key={item.id}>
                                <div className='joinPost'>
                                    <div className='joinPost-NO'>
                                        <span>{item.order}</span>
                                    </div>
                                    <div className='joinPost-icon'></div>
                                </div>
                                <span className='postName'>{item.post}</span>
                                <div className='postDetail'>
                                    <div className='postDetail-img'>
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className='postDetail-content'>
                                        <div className='postDetail-content-post'>
                                            <div className='postDetail-content-post-title'>岗位描述</div>
                                            <div className='postDetail-content-post-describe'>{item.postDescription}</div>
                                        </div>
                                        <div className='postDetail-content-condition'>
                                            <div className='postDetail-content-post-title'>任职条件</div>
                                            <div className='postDetail-content-post-describe'>{item.postConditions}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>)} 
                        </ul>
                    </div>
                </div>

                {/* 黄条 */}
                <Customized title={title} content={content}></Customized>
            </div>
        )
    }
}
Join.propTypes = {};

export default Join;