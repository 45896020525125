import React, { Component } from 'react';
import './index.scss';
import caseBG from '../../icons/caseBG.jpg';
import traffic from '../../icons/traffic.jpg';
import property from '../../icons/property.jpg';
import company from '../../icons/company.jpg';
import Customized  from '../component/customized'
import NavMobile from '../component/navMobile'

class Case extends Component {
    state={
        caseList:[
            { id: 1, title: '交通管理信息执法监管平台', content: '采集工作站 执法记录仪 由4G酒精测试仪 服务器以及软件管理平台组', img: traffic},
            { id: 2, title: '楚天中大物业系统', content: '物业缴费 全面报修 在线缴费 房屋租售 物业服务 信息通知', img: property},
            { id: 3, title: '天津金辰博科环保科技网站', content: '提高产品品牌 更好的展示企业 提高产品竞争力 降低企业销售成本',img: company},
        ],
        title:'定制每个不一样的你',
        content:'量身定制 整合提升 我们适合为不同的你 做最适合的产品' 
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { caseList, title, content} = this.state;
        return (
            <div className='case'>
                {/* 移动 */}
                <NavMobile current="case"></NavMobile>
                <div className='case-banner'>
                    <div className='case-banner-box'>
                        <img src={caseBG} alt="" />
                    </div>
                </div>

                <div className='case-title'>
                    <div className='case-title-china'>成功案例</div>
                    <div className='case-title-english'>Cases</div>
                </div>

                 <div className='case-cases'>
                   <ul>
                        {caseList.map((item, index) => <li key={item.id}>
                            <div className='cases-img'>
                                <img src={item.img} alt="" />
                                <div className='cases-tips'>
                                    <div className='cases-tips-title'>
                                        {item.title}
                                    </div>
                                    <div className='cases-tips-brief'>{item.content}</div>  
                                </div>
                            </div>
                        </li>)}
                    </ul>  
                </div> 

            {/* 定制 */}
                <Customized title={title} content={content}></Customized>
     
                 <div></div>
            </div>
        )
    }
}
Case.propTypes = {};

export default Case;