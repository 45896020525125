import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './silder.scss';

const PosterSlide = ({ data }) => {
    const settings = {
        dots: true,
        autoplay: true,
        arrows:true,
        // infinite: true,
        speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        className: 'posterSlide',
        dotsClass: 'posterSlide__dots'
    };

    return (
        <Slider {...settings} >
            {
                data.map(item => (
                    <div key={item.id}>
                        <img className="posterSlide__image" src={item.img} alt="" />
                    </div>
                ))
            }
        </Slider>
    );
};

PosterSlide.propTypes = {
    data: PropTypes.array.isRequired,
}

export default PosterSlide;