import React, { Component } from 'react';
import './index.scss';
import contactBG from '../../icons/contactBGJ.jpg';
import Map from '../index/component/map';
import NavMobile from '../component/navMobile'
import Tel from '../../icons/tel.png';
import Address from '../../icons/address.png';
import Emali from '../../icons/email.png';
import Code from '../../icons/code.png';
import Customized from '../component/customized'

class Contact extends Component {
    state={
        infoList:[
            { id: 1, img: Tel, title: '热线', name:'0514-87103886'},
            { id: 2, img: Emali,title:'邮箱',name:'452402814@qq.com'},
            { id: 3, img: Code, title: '邮编', name:'225100'},
            { id: 4, img: Address,title:'地址',name:'扬州市邗江区文昌西路国泰大厦1405'},
        ],
        title:'专注网站 用心服务',
        content:'秉承“贴心服务、无微不至”一切以用户为中心'
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { infoList, title, content} =this.state;
        return (
            <div className='contact'>
                {/* 移动 */}
                <NavMobile current="contact"></NavMobile>
                <div className='contact-banner'>
                    <div className='contact-banner-box'>
                        <img src={contactBG} alt="" />
                    </div>
                    
                </div>

                <div className='contact-title'>
                    <div className='contact-title-china'>联系我们</div>
                    <div className='contact-title-english'>Contact us</div>
                </div>

                {/* 地图 */}
                <div style={{ marginTop: '28px' }}>
                    <Map></Map>
                </div>
                 
                 {/* 图标 */}
                <div className='contact-info'>
                    <div className='contact-info-icons'>
                        <ul>
                            {infoList.map((item, index) => <li key={item.id}>
                                <div className='contactIcons'>
                                    <div className='contactIcons-img'><img src={item.img} alt="" className='Rotation' /></div>
                                </div>
                                <div className='contactTitle'>
                                    <div className='contactTitle-title'>{item.title}</div>
                                    <div className='contactTitle-name'>{item.name}</div>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
                 {/* 黄条 */}
                <Customized title={title} content={content}></Customized>
            </div>
        )
    }
}
Contact.propTypes = {};

export default Contact;