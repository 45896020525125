import PropTypes from 'prop-types';
import React, { Component } from 'react';
import aboutBG from '../../icons/aboutBG.jpg';
import './detail.scss'
import PosterSlide from './component/silder'
import Customized from '../component/customized'
import NavMobile from '../component/navMobile'

class Detail extends Component {
    state = {
       banner:[
            { id: 1, img: aboutBG},
            { id: 2, img: aboutBG},
            { id: 3, img: aboutBG},
       ],
        title: '定制每个不一样的你',
        content: '量身定制 整合提升 我们适合为不同的你 做最适合的产品' 
    };
    render() {
        const { banner, title, content} =this.state;
        return (
               
                <div>
                     {/* 移动 */}
                    <NavMobile current="/"></NavMobile>
                    <div className='detail'>
                        <div className='detail-banner'>
                            <div className='detail-banner-content'>
                                <h2>交通管理信息化执法监管平台</h2>
                                <h5>【测试测试】</h5>
                                <p>交通管理信息化执法监管平台,交通管理信息化执法监管平台,交通管理信息化执法监管平台,交通管理信息化执法监管平台,交通管理信息化执法监管平台,交通管理信息化执法监管平台,交通管理信息化执法监管平台。</p>
                            </div>
                        </div>
                        {/* <div className='detail-silder'> */}
                        <PosterSlide data={banner}></PosterSlide>
                        {/* </div> */}
                    </div>
                    {/* 定制 */}
                    <Customized title={title} content={content}></Customized>
                </div>
    
        );
    }
}

Detail.propTypes = {};

export default Detail;
