
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import './navigation.scss'
class Navigation extends Component {
    render() {
        return (
            <div>
                <div className='navigation'>
                    <div className='navigation-wrapper'>
                        <div className='navigation-wrapper-icon'></div>
                        <div className='navigation-wrapper-nav'>
                            <NavLink to='/'  className='navigation-wrapper-nav-item'>首页</NavLink>
                            <NavLink to='/about'  className='navigation-wrapper-nav-item'>关于我们</NavLink>
                            <NavLink to='/case'  className='navigation-wrapper-nav-item'>成功案例</NavLink>
                            <NavLink to='/contact'  className='navigation-wrapper-nav-item'>联系我们</NavLink>
                            <NavLink to='/join'  className='navigation-wrapper-nav-item'>加入我们</NavLink>
                        </div>
                    </div>
                   
                </div>
            </div>

        )
    }
}
export default Navigation