import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import './footer.scss'
import beian from '../../icons/beian.png'

class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='footer-link'>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-wrapper-icons'>
                            <div className='footer-link-wrapper-icons-icon'></div>
                            <div className='footer-link-wrapper-icons-word'>Copyright 2018 - 无固定期限. 
                                 choryoung.com. All Rights Reserved.
                            </div>
                        </div>
                        <div className='footer-lists'>
                          <ul>
                              <li>
                                    <ul>
                                      <li><NavLink to='/' ><font color="#fff">官网首页</font></NavLink></li>
                                      <li><NavLink to='/about'>关于我们</NavLink></li>
                                      <li><NavLink to='/case'>成功案例</NavLink></li>
                                      <li><NavLink to='/contact'>联系我们</NavLink></li>
                                      <li><NavLink to='/join'>加入我们</NavLink></li>
                                    </ul>
                                    <ul>
                                        <li><NavLink to='/about'><font color="#fff">关于我们</font></NavLink></li>
                                        <li><NavLink to='/about'>公司介绍</NavLink></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <ul>
                                        <li><NavLink to='/case' ><font color="#fff">成功案例</font></NavLink></li>
                                        <li><NavLink to='/case'>交管平台</NavLink></li>
                                        <li><NavLink to='/case'>楚天物业</NavLink></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <ul>
                                        <li><NavLink to='/contact' ><font color="#fff">联系我们</font></NavLink></li>
                                        <li><NavLink to='/contact'>电话联系</NavLink></li>
                                        <li><NavLink to='/contact'>微信联系</NavLink></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                              </li>
                          </ul>
                        </div>
                        <div className='footer-link-wrapper-wechat'>
                            <div className='footer-link-wrapper-wechat-bg'></div>
                            <div className='footer-link-wrapper-wechat-word'>微信扫一扫</div>
                        </div>
                    </div>
                </div>
                <div className='footer-copyright'> 
                    <div className='footer-copyright-wrapper'>
                        <div>© 版权所有 楚扬（江苏）网络科技有限公司</div>
                        <div>Copyright 2018 - 无固定期限. choryoung.com. All Rights Reserved. </div>
                        <div><img src={beian} alt="" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32100302010412" target="_blank"><font color="#fff">&nbsp;苏公网安备32100302010412号</font></a><a href="http://beian.miit.gov.cn/" target="_blank"><font color="#fff">&nbsp;&nbsp;|&nbsp;&nbsp;苏ICP备19024759号</font></a></div>
                    </div>
                   
                </div>
            </div>

        )
    }
}
export default Footer