import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/index/home';
import About from './pages/about/index';
import Case from './pages/case/index';
import Detail from './pages/case/detail';
import Contact from './pages/contact/index';
import Join from './pages/join/index';
// import Oa from './pages/oa/index';
import Navigation from '../src/pages/component/navigation'
import Footer from '../src/pages/component/footer'
import FooterMobile from '../src/pages/component/footerMobile'

function App() {
  return (
      <BrowserRouter>
            {/* pc */}
          <Navigation></Navigation>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/case" exact component={Case} />
        <Route path="/detail" exact component={Detail} />
        <Route path="/join" exact component={Join} />
        <Redirect to='/'></Redirect>
      </Switch>
        {/* pc */}
      <Footer></Footer>
      {/* mobile */}
      <FooterMobile></FooterMobile>
      </BrowserRouter>
  );
}

export default App;
