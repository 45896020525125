import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './customized.scss'

const TopBar = ({ title, content ,}) => {
    return (
        <div className="customized">
           <div className='customized-wrapper'>
                <div className='customized-wrapper-title'>
                    <div className='customized-wrapper-title-big'>{title}</div>
                    <div className='customized-wrapper-title-small'>{content}</div>
                </div>
                <NavLink to='/' className='customized-wrapper-button'>楚扬网络</NavLink>
           </div>
        </div>
    );
};

TopBar.propTypes = {
    title:PropTypes.string.isRequired,  
    content:PropTypes.string.isRequired,  
}

export default TopBar;
