import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './slide.scss';

const PosterSlide = ({ data }) => {
    const settings = {
        dots: true,
        autoplay: true,
        className: 'posterSlide',
        dotsClass: 'posterSlide__dots'
    };

    return (
        <div className='posterSlide-wrapper'>
                <Slider {...settings}>
                    {
                        data.map(item => (
                            <div key={item.id} className='posterSlide-box'>
                                <a href={item.url} ><img className="posterSlide__image" src={item.image} alt="" /></a>
                            </div>
                        ))
                    }
                </Slider>
    </div>
    );
};

PosterSlide.propTypes = {
    data: PropTypes.array.isRequired,
}

export default PosterSlide;