import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './recentCases.scss'

class recentCases extends Component {
    state = {

    }
    render() {
        const {intoDetail} = this.props;

        return (
            <div className='recentCases'>
                <div className='recentCases-our'>PECENT CASES</div>
                <div className='recentCases-serve'>近期的案例</div>
                <div className='recentCases-solid'></div>
                <div className='recentCases-tips'>“互联网应用 各类型 多元化解决方案全方面覆盖”</div>
                {/* pc */}
                <div className='recentCases-introduce'>
                    <a href="/case">
                        <ul>
                            <li className='recentCases-li-one'>
                                <div>
                                    <h2>【后台系统】</h2>
                                    <h1>交通管理信息<br/>化执法监管平台</h1>
                                    <h2>&nbsp;实时监察管理酒驾</h2>
                                </div>
                            </li>
                            <li className='recentCases-li-two'>
                                <div className='recentCases-li-two-child-one'>
                                    <div>
                                        <h2>【小程序+后台系统】</h2>
                                        <h1>体育中心<br />智能管理系统</h1>
                                        <h2>&nbsp;场地租赁 预约 培训整合智能化</h2>
                                    </div>
                                </div>
                                <div className='recentCases-li-two-child-two'>
                                    <div>
                                        <h2>【小程序+后台系统】</h2>
                                        <h1>楚天中大物业<br />管理系统</h1>
                                        <h2>&nbsp;节省物业管理的运营成本</h2>
                                    </div>
                                </div>
                            </li>

                            <li className='recentCases-li-three'> 
                                <div className='recentCases-li-three-child-one'>
                                    <div>
                                        <h2>【PC+H5】</h2>
                                        <h1>天津金辰博科<br />环保科技发展有限公司</h1>
                                        <h2>&nbsp;企业文化 合作的重要门户</h2>
                                    </div>
                                </div>
                                <div className='recentCases-li-three-child-two'>
                                    <div>
                                        <h2></h2>
                                        <h1><br /><br />&emsp;&emsp;&nbsp;.&emsp;.&emsp;.&emsp;待续&emsp;.&emsp;.&emsp;.</h1>
                                        <h2></h2>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </a>
                </div>

                {/* 移动 */}
                <div className='recentCases-introducem'>
                    <div className='recentCases-introducem-body'>
                        <a href="/case">
                            <div className='recentCases-introducem-box'>
                                <div>
                                    <h2>【后台系统】</h2>
                                    <h1>交通管理信息<br />化执法监管平台</h1>
                                    <h2>&nbsp;实时监察管理酒驾</h2>
                                </div>
                            </div>
                            <ul>
                                <li className="recentCases-introducem-li-one">
                                    <div>
                                        <h2>【小程序+后台系统】</h2>
                                        <h1>体育中心<br />智能管理系统</h1>
                                        <h2>&nbsp;场地租赁 预约 培训整合智能化</h2>
                                    </div>
                                </li>
                                <li className="recentCases-introducem-li-two">
                                    <div>
                                        <h2>【PC+H5】</h2>
                                        <h1>天津金辰博科<br />环保科技发展有限公司</h1>
                                        <h2>&nbsp;企业文化 合作的重要门户</h2>
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li className="recentCases-introducem-li-three">
                                    <div>
                                        <h2>【小程序+后台系统】</h2>
                                        <h1>楚天中大物业<br />管理系统</h1>
                                        <h2>&nbsp;节省物业管理的运营成本</h2>
                                    </div>
                                </li>
                                <li className="recentCases-introducem-li-four">
                                    <div>
                                        <h2></h2>
                                        <h1><br />&emsp;&emsp;&nbsp;.&emsp;.&emsp;.&emsp;待续&emsp;.&emsp;.&emsp;.</h1>
                                        <h2></h2>
                                    </div>
                                </li>
                            </ul>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
recentCases.propTypes = {
    intoDetail: PropTypes.func.isRequired,
};

export default recentCases;