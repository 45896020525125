import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './navMobile.scss'
import logo from '../../icons/icon.png'
import main from '../../img/main.png'

class TopNav extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            toggleShow: false
        };
    }
    showNav = () => {
        // console.log(123)
        this.setState({
            toggleShow: !this.state.toggleShow
        })
    }

    render() {
        const { current } = this.props
        const { toggleShow } = this.state
        return (
            <div className='navMobile'>
                <div className='navMobile-header-box'>
                    <div className='navMobile-header'>
                        <div className='navMobile-header-icon'>
                            <img src={logo} alt="" />
                        </div>
                        <div className={toggleShow ? 'navMobile-header-close' : 'navMobile-header-show'} onClick={this.showNav}>
                        </div>
                    </div>
                </div>
               


                {toggleShow && <div className='navMobile-content' onClick={this.showNav}>
                    <div className='navMobile-content-wrapper'>
                        <NavLink to='/' className={`tabMenu__btn ${current === '/' && 'tabMenu__btn--active'}`}>首页</NavLink>
                        <NavLink to='/about' className={`tabMenu__btn ${current === 'about' && 'tabMenu__btn--active'}`}>关于我们</NavLink>
                        <NavLink to='/case' className={`tabMenu__btn ${current === 'case' && 'tabMenu__btn--active'}`} >成功案例</NavLink>
                        <NavLink to='/contact' className={`tabMenu__btn ${current === 'contact' && 'tabMenu__btn--active'}`} >联系我们</NavLink>
                        <NavLink to='/join' className={`tabMenu__btn ${current === 'join' && 'tabMenu__btn--active'}`} >加入我们</NavLink>
                    </div>
                </div>}
                

            </div>
        );
    }
}

TopNav.propTypes = {};

export default TopNav;