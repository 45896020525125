import React, { Component } from 'react';
import './index.scss'
import Slide from './component/slide';
import OurServe from './component/ourServe';
import RecentCases from './component/recentCases';
import ContactUs from './component/contactUs';
import Map from './component/map';
// import Company from './component/company';
import bannerO from '../../icons/bannerO.png'
import bannerOne from '../../icons/bannerOne.png'
import bannerTwo from '../../icons/bannerTwo.jpg'
import bannerthree from '../../icons/bannerThree.jpg'
import bannerfour from '../../icons/bannerFour.jpg'
import NavMobile from '../component/navMobile'


class Index extends Component {

    state = {
        poster:[
            { id: 1, image: bannerO, url:'/index'},
            { id: 2, image: bannerOne, url: '/about'},
            { id: 3, image: bannerTwo, url: '/case'},
            { id: 4, image: bannerfour, url: '/contact' },
            { id: 5, image: bannerthree, url: '/join'}
        ],
        name:  { value: '' },
        phone: { value: '' },
        email: { value: '' },
        problem: { value: '' }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    intoDetail=()=>{
        // console.log(1111111)
        this.props.history.push('/case')
    }

    handleChangename(e) {
        this.setState({
            name: e.target.value
        })
    }
    
    handleChangephone(e) {
        var phone = e.target.value
        var regex = /^((\+)?86|((\+)?86)?)0?1[3458]\d{9}$/; 
        if (regex.test(phone)) {
            this.setState({
                phone: e.target.value
            })
        } else {
                alert('请输入正确的手机号码!');
        }
    }

    handleChangeemail(e) {
            var email = e.target.value
            var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if (regex.test(email)) {
                this.setState({
                    email: e.target.value
                })
            }else{
                alert('请输入正确的邮箱！');
            }
    }

    handleChangeproblem(e) {
        this.setState({
                problem: e.target.value
        })  
    }

    submit = () => {
        var name = this.state.name;
        var phone = this.state.phone;
        var email = this.state.email;
        var problem = this.state.problem;
        if (name !== "" && phone !== "" && email !== "" && problem!==""){
            var regex = /^((\+)?86|((\+)?86)?)0?1[3458]\d{9}$/;
            if (regex.test(phone)) {
                var emailregex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                if (emailregex.test(email)){
                    alert("提交成功");
                    return;
                }
            }
            alert("提交失败,提交参数格式有误");
        }else{
            alert("提交失败,不能有空值");
        }
    }

    render() {  
        const {poster} = this.state
        return (
            <div className='index' >
                {/* 移动 */}
                <NavMobile current="/"></NavMobile>
                {/* 轮播图 */}
                <Slide data={poster} />

                {/* 我们的服务 */}
                <OurServe data={poster}></OurServe>

                {/* 近期案例 */}
                <RecentCases intoDetail={this.intoDetail}></RecentCases>

                {/* 联系我们 */}
                <ContactUs></ContactUs>

                {/* 地图 */}
                <Map></Map>
     
                <div className="company">
                    <div className='company-wrapper'>
                        <div className='company-wrapper-consulting'>
                                <h1>欢迎咨询</h1>
                                <div><input placeholder="&nbsp;&nbsp;&nbsp;&nbsp;姓名" type="text" maxlength="8" onBlur={(e) => this.handleChangename(e)} defaultValue={this.state.name.value}/></div>
                                <div><input placeholder="&nbsp;&nbsp;&nbsp;&nbsp;联系方式" maxlength="11"  onBlur={(e) => this.handleChangephone(e)} defaultValue={this.state.phone.value}/></div>
                                <div><input placeholder="&nbsp;&nbsp;&nbsp;&nbsp;邮箱" maxlength="20" onBlur={(e) => this.handleChangeemail(e)} defaultValue={this.state.email.value}/></div>
                                <div><textarea placeholder="    请输入你咨询的问题或说明" maxlength="100" onBlur={(e) => this.handleChangeproblem(e)} defaultValue={this.state.problem.value}/></div>
                                <div><button onClick={this.submit} >提交我的咨询</button></div>   
                        </div>
                    </div>
                </div>
             </div>
        )
    }
}
Index.propTypes = {};

export default Index;