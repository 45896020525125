import React, { Component } from 'react';
import './contactUs.scss'

class contactUs extends Component {
    state = {

    }
    render() {
        return (

                <div className='contactUs'>
                    <div className='contactUs-our'>CONTACT US</div>
                    <div className='contactUs-serve'>联系我们</div>
                    <div className='contactUs-solid'></div>
                    <ul>
                        <li>
                            <div>
                            <img className='contactUs-image' src={require('../../../icons/contactphone.png')} />
                                <i>联系电话<br />0514-87103886</i>
                            </div>
                       
                        </li>
                        <li>
                            <div>
                                <img src={require('../../../icons/contactemail.png')} />
                                <i>企业邮箱<br />452402814@qq.com</i>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src={require('../../../icons/contactcode.png')} />
                                <i>邮政编码<br />225100</i>
                            </div>
                         </li>
                    </ul>
                </div>
        )
    }
}
contactUs.propTypes = {};

export default contactUs;