import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './footerMobile.scss'
import beian from '../../icons/beian.png'

class footerMobile extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            footerList:[
                {
                    id: 1,
                    name: '首页',
                    items: [
                        { id: 1, item: '关于我们', linkTo: '/about'},
                        { id: 2, item: '成功案例', linkTo:'/case' },
                        { id: 3, item: '联系我们', linkTo: '/contact'}
                    ]     
                },
                {
                    id: 2,
                    name: '关于我们',
                    items: [
                        { id: 1, item: '公司介绍', linkTo: '/about'}
                    ]
                },
                {
                    id: 3,
                    name: '成功案例',
                    items: [
                        { id: 1, item: '交管系统', linkTo: '/case'},
                        { id: 2, item: '物业系统', linkTo: '/case' },
                        { id: 3, item: '科技网站', linkTo: '/case'}
                    ]
                },
                {
                    id: 4,
                    name: '联系我们',
                    items: [
                        { id: 1, item: '电话联系', linkTo: '/contact' },
                        { id: 2, item: '微信联系', linkTo: '/contact' }
                    ]
                }
            ]
        };
    }

    render() {
        const { footerList}=this.state
        return (
            <div className='footerMobile'>
                <div className='footerMobile-box'> 
                    {footerList.map((item, index) => <div className='footerMobile-home' key={index}>
                        <div className='footerMobile-home-title'>
                            <span>{item.name}</span>
                        </div>
                        
                        <ul>
                            {item.items.map((item, index) => {
                                return <li key={item.id} className='footerMobile-li'><NavLink to={item.linkTo}>{item.item}</NavLink></li>
                            })}
                            
                        </ul>
                    </div>)}
                </div>
                <div className='footer-tips'>
                    <div className='footer-tips-box'>
                        <div>© 版权所有 楚扬（江苏）网络科技有限公司</div>
                        <div className='footer-tips-item2'>choryoung.com. All Rights Reserved.Copyright 2018 - 无固定期限.</div>
                        <div className='footer-tips-item2'><img src={beian} alt="" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32100302010412" target="_blank"><font color="#fff">&nbsp;苏公网安备32100302010412号</font></a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="http://beian.miit.gov.cn/" target="_blank"><font color="#fff">苏ICP备19024759号</font></a></div>
                    </div>
                </div>
            </div>
        );
    }
}

footerMobile.propTypes = {};

export default footerMobile;