import React, { Component } from 'react';
import './map.scss'

class Map extends Component {
    state = {

    }
    componentDidMount() {
        const { BMap,BMAP_ANIMATION_BOUNCE } = window
        var map = new BMap.Map("allmap");
        var point = new BMap.Point(119.390241, 32.39791);
        map.centerAndZoom(point, 15);
        var marker = new BMap.Marker(point);  // 创建标注
        map.addOverlay(marker);               // 将标注添加到地图中
        marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
        // map.enableScrollWheelZoom(true);
    }
    render() {
        return (
            <div className='map'>
                <div id="allmap" style={{  width: '1200px', height: '300px' }}></div>
            </div>
        )
    }
}
Map.propTypes = {};

export default Map;