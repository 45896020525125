import React, { Component } from 'react';
import './index.scss'
import aboutBG from '../../icons/aboutBG.jpg';
import aboutDot from '../../icons/aboutDot.png';
import aboutSystem from '../../img/aboutSystem.png';
import NavMobile from '../component/navMobile'

class About extends Component {
   componentDidMount() {
      window.scrollTo(0, 0);
   }

render(){
 return(
    <div className='about'>
       {/* 移动 */}
       <NavMobile current="about"></NavMobile>
       <div className='about-banner'>
          <div className='about-banner-box'>
             <img src={aboutBG} alt="" />
          </div>
         
       </div>

       <div className='about-since' ref='imgBox'>
          <div className='about-since-title'>
           SINCE 2018
          </div>
      </div>

        <div className='about-introduction'>
          <div className='about-introduction-title'>
             <div className='about-introduction-title-name'>公司简介</div>
             <div className='about-introduction-title-solid'></div>
          </div>

          <div className='about-introduction-company'>Company Introduction</div>
          <div className='about-introduction-content'>
             楚扬（江苏）网络科技有限公司成立于2018年，位于5A级写字楼国泰大厦B座1405。是一家专注于软件系统开发的研发型科技公司。
             长期为社区物业管理、地产开发、交通监管等相关行业提供智能化及信息化服务。公司拥有资深网络运营人员，网络技术工程师，
             出色的产品设计人员组成的研发团队，同时遵循“优质服务、无微不至”一切以用户为中心的经营理念，为企业产品的推广和文化发展提供专业的服务与指导。
          </div>

           <div className='about-introduction-mind'>
             <ul>
                <li>
                      <div className='about-love'>
                         <div className='about-love-tips'>心意</div>
                         <div className='about-love-name'>MIND</div>
                      </div>
                   <div className='about-loveContent'>一站式的整合构建，贴身在线的沟通回馈。更想你所想，用专业眼光提供顾问式服务，用心对待每一位用户，诚意提供细致的网络呈现方案
                  </div>
                </li>

                <li>
                   <div className='about-love'>
                      <div className='about-love-tips'>新意</div>
                      <div className='about-love-name'>NEW</div>
                   </div>
                   <div className='about-loveContent'>执着创造与众不同的网络呈现，从整体构架，设计风格，前端动效和技术手段，为诸多企业集团创造有亮点的产品
                  </div>
                </li>
             </ul>
          </div> 

      </div>

       <div className='about-system'>
          {/* pc */}
          <div className='about-system-wrapper'>
             <ul>
                <li>
                   <div className='about-system-item'>
                      <span className='about-system-item-title'>科学的开发体系:</span>
                      <span className='about-system-item-content'>准确挖掘深度需求,制定科学开发方案应用主流先进技术严密过程质量保证综合立体测试体系，及时贴心售后维护。</span>
                   </div>
                </li>
                <li>
                   <div className='about-system-item'>
                      <span className='about-system-item-title'>策略:</span>
                      <span className='about-system-item-content'>楚扬战略，业务，系统三位一体的视野结合国内外的最佳实践，以IT为创新武器，运用独特的方法论提供包括商业模式设计，业务流程重组，信息化解决方案设计与管理软件系统的实施应用。</span>
                   </div>
               </li>
                <li>
                   <div className='about-system-item'>
                      <span className='about-system-item-title'>快速:</span>
                      <span className='about-system-item-content'>IT技术和产品跟新换代的速度之快屡屡打破研发的固有节奏。更像更快的走向市场，更像保证最终的产品质量，研发团队必须拥抱敏捷的发开方式。</span>
                   </div>
               </li>
                <li>
                   <div className='about-system-item'>
                      <span className='about-system-item-title'>楚扬:</span>
                      <span className='about-system-item-content'>凭借成熟的软件项目开发经验，丰富的技术积累的研究成果，可全面理解和分析客户需求，有效应用先进技术，灵活采取适合的组织过程和开发方法，追求更加合理的工期和成本控制。</span>
                   </div>
               </li>
             </ul>
         </div>
          {/* 移动 */}
          <div className='about-system-mobile'>
             <img src={aboutSystem} alt=""/>
          </div>
      </div>

        <div className='about-powerful'> 

          <div className='about-powerful-wrapper'>

             <div className='about-powerful-wrapper-left'>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-title'>强大设计</div>
               </div>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-content'>保障细节的执行精度，是楚扬和其他公司的区别之一，楚扬的价值来自于整体效果的凸显，在细节设计上，我们同样精心。</div>
                </div>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-title'>更多内容</div>
               </div>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-content'>首屈一指的技术团队，HTML5等最新技术的运用，创造全新的高品质浏览感受，JAVA等国际主流技术架构。</div>
                </div>
             </div>
             <div className='about-powerful-wrapper-middle'>
                <img src={aboutDot} alt=""/>
             </div>
              <div className='about-powerful-wrapper-right'>
                <div className='aboutPowerful-wrapper'>
                   
                   <div className='aboutPowerful-wrapper-content' >保障细节的执行精度，是楚扬和其他公司的区别之一，楚扬的价值来自于整体效果的凸显，在细节设计上，我们同样精心。</div>
                </div>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-title'>更多细节</div>
                </div>
                <div className='aboutPowerful-wrapper'>
                 
                   <div className='aboutPowerful-wrapper-content'>首屈一指的技术团队，HTML5等最新技术的运用，创造全新的高品质浏览感受，JAVA等国际主流技术架构。</div>
                </div>
                <div className='aboutPowerful-wrapper'>
                   <div className='aboutPowerful-wrapper-title'>更多技术</div>
                </div>
             </div> 
          
          </div>

       </div> 
   
   </div>
 )
}
}
About.propTypes = {};

export default About;