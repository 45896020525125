import React, { Component } from 'react';
import './ourServe.scss'

class ourServe extends Component {
    state = {
       
    }
    render() {
        return (
            <div className='ourServe'>
                <div className='ourServe-our'>WHAT WE DO</div>
                <div className='ourServe-serve'>我们的服务</div>
                <div className='ourServe-solid'></div>
                <div className='ourServe-tips'>“专注技术 用心服务”为核心价值，秉承“优质服务 无微不至”</div>
                {/* pc */}
                <div className='ourServe-icon'>
                  <ul>
                        <li>
                            <img src={require('../../../icons/product.png')} />
                             <span>产品设计</span>
                        </li>
                        <li>
                            <img src={require('../../../icons/prototype.png')} />
                            <span>原型合计</span>
                        </li>
                        <li>
                            <img src={require('../../../icons/technology.png')} />
                            <span>技术服务</span>
                        </li>
                        <li>
                            <img src={require('../../../icons/operations.png')} />
                            <span>运维服务</span>
                        </li>
                  </ul>
                </div>
                {/* 移动 */}
                <div className='ourServe-iconm'>
                   <ul>
                        <li>
                            <img src={require('../../../img/product.png')} />
                            <span>产品设计</span>
                        </li>

                        <li>
                            <img src={require('../../../img/prototype.png')} />
                            <span>原型合计</span>
                        </li>
                        <li>
                            <img src={require('../../../img/technology.png')} />
                            <span>技术服务</span>
                        </li>
                        <li>
                            <img src={require('../../../img/operations.png')} />
                            <span>运维服务</span>
                        </li>
                   </ul>
               </div>
            </div>
        )
    }
}
ourServe.propTypes = {};

export default ourServe;